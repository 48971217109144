import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import formConfig from "../../../../formJsonData/Operations/Vessel/VesseLOperations/TMLOperation.json";
import H_H_formConfig from "../../../../formJsonData/Operations/Vessel/VesseLOperations/H&HOperation.json";
import supervission_formConfig from "../../../../formJsonData/Operations/Vessel/VesseLOperations/supervission.json";
import DraftSurvey_formConfig from "../../../../formJsonData/Operations/Vessel/VesseLOperations/DraftSurvey.json";
import Form from "../../../../components/common/Form";
import { sampleMarkOptionsApi } from "../../../../services/api";
import { postDataFromApi } from "../../../../services/commonServices";
import commonFields from "../../../../formJsonData/Operations/commonFields.json";
import { useParams } from "react-router-dom";
import { decryptDataForURL } from "../../../../utills/useCryptoUtils";
formConfig['sections'][0].fields = commonFields
const TMLOperations = () => {
  let { TMLType } = useParams()
  TMLType = TMLType ? decryptDataForURL(TMLType) : ''
  if(TMLType=="H&H"){
    formConfig['sections'][1]=H_H_formConfig;
  }
  else  if(TMLType=="Supervision"){
    formConfig['sections'][1]=supervission_formConfig;
  }
  else if(TMLType=="Draft Survey"){
    formConfig['sections'][1]=DraftSurvey_formConfig;
  }
  
  let count = 0
  useEffect(() => {
    let tabTitle = formConfig['sections'][1]['tabs'][0].label
    formConfig['sections'][1]['tabs'][0].label = TMLType || tabTitle
    if (["Pre-Shipment (PSI)", "Quality Analysis"].includes(TMLType)) {
      if (count === 0) {
        addFieldsinconfig(formConfig['sections'][1]['tabs'][0])
        count = 1
      }
    }
  }, [TMLType])

  const addFieldsinconfig = (array) => {
    var headerfieldValue
    var tabFields

    if (TMLType === "Pre-Shipment (PSI)") {
      headerfieldValue = {
        "name": "jism_lot_no",
        "type": "text",
        "label": "Lot No.",
      }
      tabFields = {
        "name": "jism_lot_no",
        "type": "text",
        "label": "Lot No.",
      }
      array.headers = [headerfieldValue, ...array.headers];
      array.rows[0] = [tabFields, ...array.rows[0]];
    }
    headerfieldValue = {
      "name": "jism_composite_lot",
      "type": "radio",
      "label": "Is Composite or Lot",
    }
    tabFields = {
      "name": "jism_composite_lot",
      "type": "radio",
      "label": "Is Composite or Lot",
      "options": ["Composite", "Lot"],
    }
    array.headers = [headerfieldValue, ...array.headers];
    array.rows[0] = [tabFields, ...array.rows[0]];
    formConfig['sections'][1]['tabs'][0] = array
  }
  const [masterResponse, setMasterResponse] = useState([]);
  const getSampleIdsMasterData = async (sampleId) => {
    try {
      let tempBody = {
        jism_id: sampleId,
      };
      let res = await postDataFromApi(sampleMarkOptionsApi, tempBody);
      if (res.data && res.data.status === 200 && res.data.data) {
        const transformedData = res.data.data.sample_marks.map((value) => ({
          id: value,
          name: value,
        }));
        const bodyToPass = {
          model: "smpl_set_smpljson",
          data: transformedData,
        };
        let isExists = false;
        let filterData = masterResponse.filter((model) => {
          if (model.model === "smpl_set_smpljson") {
            model.data = transformedData;
            isExists = true;
          }
          return true;
        });
        if (isExists) {
          setMasterResponse(filterData);
        } else {
          setMasterResponse((prev) => [...prev, bodyToPass]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Row className="rowWidthAdjust">
      <Col>
        <Form formConfig={formConfig} masterResponse={masterResponse} setMasterResponse={setMasterResponse} useForComponent={"OperationDetails"} getSampleIdsMasterData={getSampleIdsMasterData} />
      </Col>
    </Row>
  );
};

export default TMLOperations;
