import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import React from "react";
import PropTypes from 'prop-types';
import { handleJIUpdateStatus, handleJIValidation } from "../../commonHandlerFunction/jobinstructionHandlerFunctions";
const JIButtons = ({
  action,
  tabOpen,
  setIsPopupOpen,
  setJRFCreationType,
  setInwardBtnchange,
  formData,
  subTableData,
  EditRecordId,
  viewOnly,
  handleBackButtonFunction,
  navigate,
  editReordType,
  setJrfCreationType,
  handleSubmit,
  formConfig,
  setIsOverlayLoader,
  setIsRejectPopupOpen,
  useForComponent,
  activeTab,
  setActiveTab,
  testMemoSetData,
  isDisplayNewAddOption
}) => {
  const { t } = useTranslation();
  const translate = t;
  const getTotalNotFilledCount = () => {
    let count = 0;
    testMemoSetData.forEach((tab, tabIndex) => {
      count += formData["tab_" + tabIndex]?.["noFilledCount"];
    });
    return count;
  };
  return (
    <div className="submit_btns">
      {!useForComponent ? !viewOnly && (
        <React.Fragment>
          <Button
            type="button"
            className="cancelBtn"
            id="submit_btn3"
            onClick={() => {
              handleBackButtonFunction()
            }}
          >
            {translate("common.backBtn")}
          </Button>
          <>
            <button
              type="button"
              className="saveBtn"
              id="submit_btn2"
              data-name="save"
              onClick={(e) =>
                handleJIValidation(
                  handleSubmit,
                  setJrfCreationType,
                  setIsPopupOpen,
                  "save"
                )
              }
            >
              {translate("common.saveBtn")}
            </button>
            <Button
              type="button"
              className="rejectBtn"
              id="submit_btn1"
              disabled={
                editReordType !== "nomination"
              }
              onClick={(e) =>
                handleJIValidation(
                  handleSubmit,
                  setJrfCreationType,
                  setIsPopupOpen,
                  "post"
                )
              }
            >
              {translate("common.postBtn")}
            </Button>
            {
              editReordType !== "nomination" && (
                <Button
                  type="button"
                  className="submitBtn"
                  id="submit_btn1"
                  onClick={(e) =>
                    handleJIUpdateStatus(formData, formConfig, setIsOverlayLoader, editReordType, navigate)
                  }
                  disabled={
                    editReordType !== "nomination" && subTableData.length === 0
                  }
                >

                  {!editReordType ? "Scope of Work" : "Parameters"}
                </Button>
              )
            }

          </>
        </React.Fragment>
      ) : useForComponent === "OperationDetails" ? <React.Fragment>

        <Button
          type="button"
          className="cancelBtn"
          id="submit_btn3"
          onClick={() => {
            handleBackButtonFunction()
          }}
        >
          {translate("common.backBtn")}
        </Button>
        <button
          type="button"
          className="saveBtn"
          id="submit_btn2"
          data-name="save"
          onClick={(e) => {
            setJRFCreationType("save");
            setIsPopupOpen(true);
          }
          }
        >
          {translate("common.saveBtn")}
        </button>
        <Button
          type="button"
          className="rejectBtn"
          id="submit_btn1"
          disabled={subTableData.length === 0}
          onClick={(e) => {
            setJRFCreationType("post");
            setIsPopupOpen(true);
          }
          }
        >
          {translate("common.postBtn")}
        </Button>
      </React.Fragment> : useForComponent === "OperationDetailsAssignment" ? <React.Fragment>

        <Button
          type="button"
          className="cancelBtn"
          id="submit_btn3"
          onClick={() => {
            handleBackButtonFunction()
          }}
        >
          {translate("common.backBtn")}
        </Button>
        <button
          type="button"
          className="saveBtn"
          id="submit_btn2"
          data-name="save"
      
        >
          {translate("common.saveBtn")}
        </button>
        <Button
          type="button"
          className="submitBtn"
          id="submit_btn1"
          disabled={subTableData.length === 0 || isDisplayNewAddOption}
          onClick={(e) => {
            setJRFCreationType("post");
            setIsPopupOpen(true);
          }
          }
        >
          Post to JRF
        </Button>
        <Button
          type="button"
          className="submitBtn"
          id="submit_btn1"
          disabled={subTableData.length === 0 || isDisplayNewAddOption}
          onClick={(e) => {
            setJRFCreationType("postOther");
            setIsPopupOpen(true);
          }
          }
        >
          Post to Other TPI
        </Button>
      </React.Fragment> : useForComponent !== "OperationsList" ? <React.Fragment>
        <Button
          type="button"
          className="cancelBtn"
          id="submit_btn3"
          onClick={() => {
            handleBackButtonFunction()
          }}
        >
          {translate("common.backBtn")}
        </Button>
        <>

          {
            useForComponent === "OperationsJIDetails" ? (
              <>
                {
                  activeTab !== "1-2" ? (
                    <Button
                      type="button"
                      className="submitBtn"
                      id="submit_btn1"
                      onClick={(e) => {
                        const spValue = activeTab.split('-')

                        const newValue = parseInt(spValue[1]) + 1
                        console.log('spValue', spValue, newValue)
                        setActiveTab('1-' + newValue)
                      }
                      }
                    >

                      Next
                    </Button>
                  ) : (<>
                    <Button
                      type="button"
                      className="rejectBtn"
                      id="submit_btn1"
                      onClick={(e) => setIsRejectPopupOpen(true)}
                    >
                      {translate("common.rejectBtn")}
                    </Button>
                    <Button
                      type="button"
                      className="submitBtn"
                      id="submit_btn1"
                      onClick={(e) =>
                        handleJIUpdateStatus(formData, formConfig, setIsOverlayLoader, editReordType, navigate, 1, "accepted")
                      }
                    >
                      {translate("common.acceptBtn")}
                    </Button></>)
                }

              </>
            ) : useForComponent === "OperationDetailsOtherTPI" ? !viewOnly && !getTotalNotFilledCount() && (
              <>
                <Button
                  type="button"
                  className="submitBtn"
                  id="submit_btn1"
                >
                  {translate("common.postBtn")}
                </Button>
              </>
            ) : null
          }

        </>
      </React.Fragment> : null}
    </div>
  );
};

JIButtons.propTypes = {
  action: PropTypes.func, // Function for handling actions
  tabOpen: PropTypes.bool, // Boolean to indicate if a tab is open
  setIsPopupOpen: PropTypes.func, // Function to set popup open state
  setJRFCreationType: PropTypes.func, // Function to set JRF creation type
  setInwardBtnchange: PropTypes.func, // Function to handle inward button changes
  formData: PropTypes.object, // Form data, object type
  subTableData: PropTypes.array, // Array of sub-table data
  EditRecordId: PropTypes.any, // ID of the record being edited; use 'any' or specify type if known
  viewOnly: PropTypes.bool, // Boolean to indicate view-only mode
  handleBackButtonFunction: PropTypes.func, // Function for handling back button
  navigate: PropTypes.func, // Function for navigation
  editReordType: PropTypes.string, // Type of record being edited, string type
  setJrfCreationType: PropTypes.func, // Function to set JRF creation type
  handleSubmit: PropTypes.func, // Function to handle form submission
  formConfig: PropTypes.object, // Configuration for the form
  setIsOverlayLoader: PropTypes.func, // Function to set overlay loader state
  setIsRejectPopupOpen: PropTypes.func, // Function to set reject popup open state
  useForComponent: PropTypes.string, // String to specify use for component
  activeTab: PropTypes.string, // String to indicate the active tab
  setActiveTab: PropTypes.func, // Function to set active tab
  testMemoSetData: PropTypes.object, // Object containing test memo data
  isDisplayNewAddOption: PropTypes.bool // Boolean to control display of new add option
};


export default JIButtons;
