import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import formConfig from "../../../formJsonData/Operations/Vessel/VesselJIDetails.json";
import Form from "../../../components/common/Form";
import { postDataFromApi } from "../../../services/commonServices";
import { MasterListApi } from "../../../services/api";
import commonFields from "../../../formJsonData/Operations/commonFields.json";
formConfig['sections'][0].fields = commonFields
const VesselJIDetais = () => {
  const [masterResponse, setMasterResponse] = useState([]);

  useEffect(() => {
    getBranchDetailsData();
  }, []);
  const getBranchDetailsData = async () => {
    try {
      let res = await postDataFromApi(MasterListApi, { is_dropdown: true, model_name: "branch" });
      if (res?.data?.status === 200 && res.data.data) {
        const clientData = res.data.data.map((client) => ({
          id: client[0],
          name: client[1],
        }));

        const bodyToPass = {
          model: "fk_branchid",
          data: clientData,
        };
        setMasterResponse((prev) => [...prev, bodyToPass]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Row className="rowWidthAdjust">
      <Col>
        <Form formConfig={formConfig} masterResponse={masterResponse} setMasterResponse={setMasterResponse} useForComponent="OperationsJIDetails"/>
      </Col>
    </Row>
  );
};

export default VesselJIDetais;
