import React, { useEffect, useState } from "react";
import RenderFields from "./RenderFields";
import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";

import ActionOptionsTable from "./ActionOptionsTable";
import { getSelectedOptionName } from "../../services/commonFunction";
import PropTypes from "prop-types";
import SampleVerificationDetals from "./commonModalForms/SampleVerificationDetals";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
const RenderTableForDraftSurveySection = ({
  section,
  sectionIndex,
  handleFieldChange,
  formData,
  setFormData,
  formErrors
}) => {
  const [addInterm, setAddInterm] = useState(false)
  const getSingleCommonfield = (name, type, label = "") => {

    return (<RenderFields
      field={{
        width: 4,
        name: name,
        label: label,
        type: type,
        options: [],
        required: true,
        fieldWidth: 50,
        multiple: true,
      }}
      sectionIndex={sectionIndex}
      fieldIndex={1 * 100 + 1}
      formData={formData}
      handleFieldChange={handleFieldChange}
      formErrors={formErrors}
      renderTable={true}
      tableIndex={sectionIndex}
    />)
  }
  return (
    <div key={sectionIndex} className="row my-2 mx-0 bg-white">

      <Card className="Scrollable">
        <CardBody>
          <CardTitle tag="h5">{section.title}</CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
            Quantity On Board On Ship's Account
          </CardSubtitle>
          <table className="table table-white responsive borderless no-wrap mt-3 align-middle renderTable">
            <thead>
              <tr>
                <th>Title</th>
                <th>Initial</th>
                {
                  addInterm && (<>
                    <th>Interim</th>
                    <th>Final</th>
                  </>)
                }

                <th>Unit</th>
              </tr>
            </thead>
            <tbody>
              {
                section.headers1.map((header) => {
                  return (<tr>
                    <td>{header.label}</td>
                    <td>{getSingleCommonfield(header.name + "-initial", "text")}</td>
                    {
                      addInterm && (<>
                        <td>{getSingleCommonfield(header.name + "-interim", "text")}</td>
                        <td>{getSingleCommonfield(header.name + "-final", "text")}</td>
                      </>)
                    }

                    <td>{getSingleCommonfield(header.name + "-unit", "text")}</td>
                  </tr>)
                })
              }
              <tr>
                <td>Total</td>
                <td>0</td>
                {
                  addInterm && (
                    <>
                      <td>0</td>
                      <td>0</td>
                    </>
                  )
                }
                <td>MTS</td>
              </tr>
            </tbody>
          </table>
          <div>
            <h6><b>Change on ship's Account {0}</b></h6>
            <h6><b>Balance Quantity To Be Discharged {0}</b></h6>
          </div>
          <div className="submit_btns">
            {
              !addInterm && <button
              type="button"
              className="saveBtn"
              id="submit_btn3"
              onClick={()=>setAddInterm(true)}
            >
              Add Interim
            </button>
            }
            {
              addInterm && (<button
                type="button"
                className="saveBtn"
                id="submit_btn3"
              >
                Add Final
              </button>)
            }
          </div>

          <hr />
          <table className="table table-white responsive borderless no-wrap mt-3 align-middle renderTable">
            <tbody>
              {
                section.headers2.map((header) => {
                  return (<tr>
                    <td>{header.label}</td>
                    <td>{getSingleCommonfield(header.name + "-initial", "text")}</td>
                    {
                      addInterm && (
                        <>
                          <td>{getSingleCommonfield(header.name + "-interim", "text")}</td>
                          <td>{getSingleCommonfield(header.name + "-final", "text")}</td>
                        </>
                      )
                    }

                    <td>{getSingleCommonfield(header.name + "-unit", "text")}</td>
                  </tr>)
                })
              }
              <tr>
                <td>Total</td>
                <td>0</td>
                {
                  addInterm && (
                    <>
                      <td>0</td>
                      <td>0</td>
                    </>)
                }

                <td>MTS</td>
              </tr>
            </tbody>
          </table>
          <div>
            <h6><b>Difference in Displacement {0}</b></h6>
            <h6><b>Change on ship's Account {0}</b></h6>
            <h6><b>Quantity Discharged / Loaded {0}</b></h6>
            <h6><b>Rounded of Qty. {0}</b></h6>
          </div>

          <div>
            {getSingleCommonfield("opsvd_remarks", "text", 'Remarks')}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

RenderTableForDraftSurveySection.propTypes = {
  section: PropTypes.string,
  sectionIndex: PropTypes.number,
  formData: PropTypes.object,
  handleFieldChange: PropTypes.func,
};


export default RenderTableForDraftSurveySection;
