import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const InputNumber = ({ field }) => {
  const {
    name,
    label,
    value,
    onChange,
    required,
    error,
    placeholder,
    readOnly,
    tooltip,
    characterLimit,
    fieldWidth,
    minValue,
    maxValue,
    pattern,
    errorMsgs,
    actionClicked,
    styleName,
    handleFieldBlur,
    upperClass,
  } = field;

  const [errorMsg, setErrorMsg] = useState("");
  const minMaxCondition = styleName !== "InputNum";
  useEffect(() => {
    const regex = new RegExp(pattern);

    if (value !== "") {
      if (regex.test(value)) {
        setErrorMsg("");
      } else {
        setErrorMsg(
          errorMsgs
            ? errorMsgs["pattern"]
            : "Plase enter valid value for " + label
        );
      }
    } else if (required) {
      setErrorMsg(errorMsgs ? errorMsgs["required"] : label + " is required");
    }
  }, [value]);
  const handleOnchangeEvent = (e) => {
    if (characterLimit) {
      var newValue = e.target.value;
      if (newValue >= 0) {
        e.target.value = newValue
      }
      else {
        e.target.value = Math.abs(newValue);
      }
    }
    onChange(e)
  }
  return (
    <div
      className={
        "form-group " + upperClass + (styleName !== " InputNum" ? " my-2" : "")
      }
    >
      {label && (
        <label htmlFor={name} style={{ width: `${18}%` }}>
          {label}
          <span className="required_mark"> {required ? ` *` : null}</span>
        </label>
      )}
      <div className={"w-" + (fieldWidth ?? "75") + " d-inline-block mx-2"}>
        <input
          type="number"
          id={name}
          name={name}
          value={value}
          onChange={handleOnchangeEvent}
          required={required}
          placeholder={placeholder}
          className={"form-control rounded-2 "}
          readOnly={readOnly}
          title={tooltip}
          maxLength={characterLimit}
          onBlur={handleFieldBlur || null}
          {...(minMaxCondition && { min: minValue || 0, max: maxValue || 99 })}
          style={{ width: "100%" }}
        />

        {errorMsg && actionClicked ? (
          <p className="text-danger errorMsg">{errorMsg}</p>
        ) : null}
        {error && actionClicked && <p className="text-danger">{error}</p>}
      </div>
      {name === "jrf_finalize_timeframe" && <label htmlFor="days">Days</label>}
    </div>
  );
};

InputNumber.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any, // Use `any` if the value can be of any type
    onChange: PropTypes.func,
    required: PropTypes.bool,
    error: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    tooltip: PropTypes.string,
    characterLimit: PropTypes.number,
    fieldWidth: PropTypes.string,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    pattern: PropTypes.string,
    errorMsgs: PropTypes.arrayOf(PropTypes.string),
    actionClicked: PropTypes.func,
    styleName: PropTypes.string,
    handleFieldBlur: PropTypes.func,
    upperClass: PropTypes.string,
  }),
};
export default InputNumber;
