import React, {  useState } from "react";
import RenderFields from "../RenderFields";
import PropTypes from 'prop-types';

import {
  Row
} from "react-bootstrap";
import OverlayLoading from "../OverlayLoading";
import { checkSampleIdAvailibility } from "../commonHandlerFunction/sampleInwardHandlerFunctions";
const ModalInward = ({
  section,
  sectionIndex,
  formData,
  handleFieldChange,
  formErrors,
  tableData,
  updatedMasterOptions,
  setPopupOpenAssignment,
  onActionHandleClick,
  actionName,
  handleCloseInwardPopup,
  editableIndex,
  isBtnclicked,
  isOverlayLoader,
  setIsOverlayLoader
}) => {
  const [isSampleIdAvailable, setIsSampleIdAvailable] = useState(actionName === "Save")
  const InwardUnits = formData[1]?.jrf_commodity_detail?.cmd_unit || [];
  let unitoptions = [];
  InwardUnits.map((singleOpt) => {
    unitoptions.push({
      name: singleOpt.cu_name,
      id: singleOpt.cu_symbol,
    });
  });
  if (InwardUnits.length === 0) {
    unitoptions.push({
      name: "GM",
      id: "gm",
    });
  }
  const getCustomCellValues = (cell) => {
    if (cell.name == "smpl_detail_smpl_qty_unit") {
      cell.type = "text"
    }
    else if (cell.name === "sample_id") {
      if (actionName === "Save") {
        // cell.type = "label"
      }
      else {
        cell.type = "text"
      }

    }
    else if (cell.name == "smpl_detail_dos") {
      cell.viewOnly = true;

    } else if (cell.name === "smpl_detail_seal_number") {

      if (
        formData[1]?.[
        "smpl_detail_pkging_condition" +
        "_" +
        (actionName === "Save" ? editableIndex : tableData.length)
        ] === "Unsealed"
      ) {
        cell.required = false;
        cell.readOnly = true;

      } else {
        cell.required = true;
        cell.readOnly = false;
      }
    }
    else if(cell.name === "smpl_detail_sample_mark" &&  formData[0]?.jrf_is_ops){
      cell.type = "label"
    }
    return cell;
  };
  const handleCustomFieldChange = (
    sectionIndex,
    fieldName,
    value,
    type = "",
    isChecked = ""
  ) => {
    let lastname = "_" + (actionName === "Save" ? editableIndex : tableData.length);
    let actualName = fieldName.split(lastname)[0];
    if (actualName === "sample_id") {
      checkSampleIdAvailibility(value, setIsOverlayLoader, setIsSampleIdAvailable)
    }
    handleFieldChange(sectionIndex, fieldName, value, type, isChecked)

  }
  return (
    <div className="popupSearchContainerBG">

      <div className="popupInwardModal popupWidthAdjustmentInward">
        <div className="rejectSearchCross">
          <button
            onClick={handleCloseInwardPopup}
            className="nonNativeButton2"
            aria-label="Reject Button"
          >
            <i className="bi bi-x-lg h4"></i>
          </button>
        </div>

        {section.rows.map((row, rowIndex) => (
          <>
            <Row className="autoWidthImportant">
              <h2 className="modalHeader">Sample Inward Details</h2>
              {isOverlayLoader && <OverlayLoading />}
              {section.headers.map((cell, cellIndex) => (
                <div
                  className={"col-md-" + cell?.width}
                  key={"Modal-Header-" + cellIndex}
                >
                  <RenderFields
                    field={getCustomCellValues(cell)}
                    sectionIndex={sectionIndex}
                    fieldIndex={rowIndex * 100 + cellIndex}
                    formData={formData}
                    handleFieldChange={handleCustomFieldChange}
                    formErrors={formErrors} // Pass formErrors to RenderFields
                    renderTable={true}
                    tableIndex={rowIndex}
                    customName={
                      cell.name +
                      "_" +
                      (actionName === "Save" ? editableIndex : tableData.length)
                    }
                    masterOptions={updatedMasterOptions}
                    upperClass="popupUpperClass"
                  />
                </div>
              ))}
            </Row>
          </>
        ))}
        <div className="popupInwardButtonsContainer">
          <div className="popupSearchButtons">
            <button type="button" onClick={handleCloseInwardPopup}>
              Cancel
            </button>
            <button
              type="button"
              disabled={!isSampleIdAvailable || isBtnclicked}
              onClick={() => {
                onActionHandleClick(actionName);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
ModalInward.propTypes = {
  section: PropTypes.object,  // Adjust if you know the exact shape
  sectionIndex: PropTypes.number,
  formData: PropTypes.object,  // Adjust if you know the exact shape
  handleFieldChange: PropTypes.func,
  formErrors: PropTypes.object,  // Adjust if you know the exact shape
  tableData: PropTypes.array,  // Adjust if you know the type of elements
  updatedMasterOptions: PropTypes.object,  // Adjust if you know the exact shape
  setPopupOpenAssignment: PropTypes.func,
  onActionHandleClick: PropTypes.func,
  actionName: PropTypes.string,  // Adjust if you expect different types
  handleCloseInwardPopup: PropTypes.func,
  editableIndex: PropTypes.number,
  isBtnclicked: PropTypes.bool,
  isOverlayLoader: PropTypes.bool,
  setIsOverlayLoader: PropTypes.func
};
export default ModalInward;
