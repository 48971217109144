import React, {  useState } from "react";
import { Row, Col } from "reactstrap";

import formConfig from "../../formJsonData/Operations/jobinstructions/JrfInstruction_analys.json";
import Form from "../../components/common/Form";

const JfInstructionAnalys = () => {
  const [masterResponse, setMasterResponse] = useState([]);

  return (
    <Row className="rowWidthAdjust">
      <Col>
        <Form formConfig={formConfig} masterResponse={masterResponse} setMasterResponse={setMasterResponse}/>
      </Col>
    </Row>
  );
};

export default JfInstructionAnalys;
