import React, {  useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import formConfig from "../../../../formJsonData/Operations/Vessel/VesseLOperations/TMLAnalysOperation.json";
import Form from "../../../../components/common/Form";
import { sampleMarkOptionsApi } from "../../../../services/api";
import { postDataFromApi } from "../../../../services/commonServices";
import commonFields from "../../../../formJsonData/Operations/commonFields.json";
import { decryptDataForURL } from "../../../../utills/useCryptoUtils";
import { useParams } from "react-router-dom";
formConfig['sections'][0].fields=commonFields
const TMLAnalysisOperations = () => {
  const [masterResponse, setMasterResponse] = useState([]);
  let { TMLType } = useParams()
  TMLType = TMLType ? decryptDataForURL(TMLType) : ''
  useEffect(()=>{
    console.log('TMLType',TMLType)
    if(TMLType===""){

    }
  },[])
  const getSampleIdsMasterData = async (ji_id,jis_id) => {
    try {
      let tempBody = {
        ji_id: ji_id,
        jis_id: jis_id,
      };
      let res = await postDataFromApi(sampleMarkOptionsApi, tempBody);
      if (res.data && res.data.status === 200 && res.data.data) {
        const transformedData = res.data.data.sample_marks.map((value) => ({
          id: value,
          name: value,
        }));
        const bodyToPass = {
          model: "smpl_set_smpljson",
          data: transformedData,
        };
        let isExists = false;
        let filterData = masterResponse.filter((model) => {
          if (model.model === "smpl_set_smpljson") {
            model.data = transformedData;
            isExists = true;
          }
          return true;
        });
        if (isExists) {
          setMasterResponse(filterData);
        } else {
          setMasterResponse((prev) => [...prev, bodyToPass]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Row className="rowWidthAdjust">
      <Col>
        <Form formConfig={formConfig} masterResponse={masterResponse} setMasterResponse={setMasterResponse} useForComponent={"OperationDetailsAssignment"} getSampleIdsMasterData={getSampleIdsMasterData} />
      </Col>
    </Row>
  );
};

export default TMLAnalysisOperations;
