import { toast } from "react-toastify";
import { deleteDataFromApi, getDataFromApi, GetTenantDetails, postDataFromApi, putDataFromApi } from "../../../services/commonServices";
import { getActivityDataApi, getAllPortDataApi, getBranchDetailsApi, getClientDataApi, getJIQualityAnalysisCreateApi, getJIQualityAnalysisDeleteApi, getJIsowandactivityApi, getOperationTypeDataApi, getscopeofworkDataApi, getScopeworkCreateApi, getScopeworkDeleteApi, getScopeworkUpdateApi, getSubCommodityDataApi, MasterListApi, OPESGrouoparametersApi } from "../../../services/api";
import { encryptDataForURL } from "../../../utills/useCryptoUtils";

export const handleJIValidation = async (
  handleSubmit,
  setJRFCreationType,
  setIsPopupOpen,
  type
) => {
  let isValidate = handleSubmit();
  if (!isValidate) {
    return false;
  }
  setJRFCreationType(type);
  setIsPopupOpen(true);
};

export const getcheckStatus = (currentStatus, recordType, type, mainStatus) => {
  if (mainStatus) {
    return mainStatus
  }
  if (!currentStatus) {
    return "saved"
  }
  else {
    if (!recordType) {
      if (currentStatus === "saved") {
        return "created"
      }
      return currentStatus
    }
    else {
      if (recordType === "analysis") {
        if (currentStatus === "created") {
          return "pre-analysis"
        }
        else if (currentStatus === "pre-analysis") {
          return "analysis"
        }
        return currentStatus
      }
      else if (recordType === "nomination") {
        if (type === "post") {
          return "posted"
        }
        return currentStatus
      }
    }
  }
}

export const handleJICreateOrUpdate = async (
  formData,
  formConfig,
  setIsOverlayLoader,
  setIsPopupOpen,
  type,
  setTabOpen,
  setFormData,
  editReordType,
  navigate
) => {
  let payloadData = {
    ji_date: formData[0].ji_date,
    fk_clientid: formData[0].fk_clientid,
    fk_companyid: formData[0].fk_companyid,
    ji_sent_through: formData[0].ji_sent_through,
    fk_commodityid: formData[0].fk_commodityid,
    fk_subcommodityid: formData[0].fk_subcommodityid,
    ji_totalqty: formData[0].ji_totalqty,
    ji_is_ecertification: formData[0].ji_is_ecertification !== "Print Hard Copy" ? true : false,
    fk_placeworkid: formData[0].fk_placeworkid,
    fk_supplierid: formData[0].fk_supplierid,
    ji_reference: formData[0].ji_reference,
    ji_dispatch_address: formData[0].ji_dispatch_address,
    ji_payment_terms: formData[0].ji_payment_terms,
    ji_type_of_sampling: formData[0].ji_type_of_sampling,
    ji_type_of_analysis: formData[0].ji_type_of_analysis,
    ji_analysis: formData[0].ji_analysis,
    fk_branchid: formData[0].fk_branchid,
    fk_userbranchheadid: formData[0].fk_userbranchheadid,
    fk_useropsexecutiveid: formData[0].fk_useropsexecutiveid,
    fk_useropsexecutiveheadid: formData[0].fk_useropsexecutiveheadid ? parseInt(formData[0].fk_useropsexecutiveheadid) : '',
    fk_usersalespersonid: formData[0].fk_usersalespersonid,
    ji_nameofoperationmode: formData[0].ji_nameofoperationmode,
    fk_operationtypetid: formData[0].fk_operationtypetid,
    ji_internal_status: getcheckStatus(formData[0]?.ji_internal_status, editReordType, type)

  }
  if (type === "post") {
    payloadData.status = "posted";
  } else {
    payloadData.status = "created";
  }
  let res;
  setIsOverlayLoader(true)
  if (formData[0].ji_id) {
    let MainData = {
      job_inst_data: payloadData,
    };
    MainData.ji_id = formData[0].ji_id;
    res = await putDataFromApi(formConfig.apiEndpoints.update, MainData);
  } else {
    let MainData = {
      job_inst_data: payloadData,
    };
    res = await postDataFromApi(formConfig.apiEndpoints.create, MainData);
  }
  if (res?.data?.status === 200) {
    toast.success(res?.data?.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setIsOverlayLoader(false)
    setIsPopupOpen(false);
    let responseData = res.data.data
    getSingleJiRecord(formConfig.apiEndpoints.read, responseData.ji_id, setFormData, setTabOpen, setIsOverlayLoader, editReordType)
    if (type === "post") {
      navigate('/operation/jrfInstructionListing')
    }
  } else {
    toast.error(res.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  setIsPopupOpen(false);
  setIsOverlayLoader(false)
};
export const handleJIUpdateStatus = async (
  formData,
  formConfig,
  setIsOverlayLoader,
  editReordType,
  navigate,
  isMainStatusChange,
  mainStatus = "",
  remarkText = ""
) => {
  const newStatus = getcheckStatus(formData[0]?.ji_internal_status, editReordType, "fillNew", mainStatus)
  console.log('newStatus', newStatus)
  let payloadData = {
    ji_internal_status: newStatus
  }
  if (isMainStatusChange) {
    payloadData.status = mainStatus
    payloadData.rejectRemark = remarkText
  }
  let res;
  setIsOverlayLoader(true)
  let MainData = {
    job_inst_data: payloadData,
  };

  MainData.ji_id = formData[0].ji_id;
  res = await putDataFromApi(formConfig.apiEndpoints.update, MainData);
  if (res?.data?.status === 200) {
    toast.success(res?.data?.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setIsOverlayLoader(false)
    let responseData = res.data.data
    if (isMainStatusChange) {
      navigate('/operation/vessel-ji-list')
    }
    else {
      navigate(!editReordType ? '/operation/jrfInstructionListing/job-instruction-analysis/' + encryptDataForURL(formData[0]?.ji_id) + '/' + encryptDataForURL('analysis') : '/operation/jrfInstructionListing/job-instruction-nomination/' + encryptDataForURL(formData[0]?.ji_id) + '/' + encryptDataForURL('nomination'))
    }
  } else {
    toast.error(res.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  setIsOverlayLoader(false)
};
export const getSubCommodityData = async (cmd_id, setMasterResponse, setIsOverlayLoader) => {
  try {
    setIsOverlayLoader(true)
    let res = await postDataFromApi(getSubCommodityDataApi, {
      cmd_id: cmd_id
    });
    if (res?.data?.status === 200 && res.data.data) {
      const clientData = res.data.data.sub_commodity.map((client) => ({
        id: client.sub_cmd_id,
        name: client.sub_cmd_name,
      }));
      const bodyToPass = {
        model: "fk_subcommodityid",
        data: clientData,
      };
      setMasterResponse((prev) => [...prev, bodyToPass]);
    }
  } catch (error) {
    console.error(error);
  } finally {
    setTimeout(() => {
      setIsOverlayLoader(false)
    }, 10)
  }
};
export const getclientDetails = async (customer_id, setFormData, setIsOverlayLoader) => {
  try {
    setIsOverlayLoader(true)
    let res = await postDataFromApi(getClientDataApi, {
      customer_id: customer_id
    });
    if (res?.data?.status === 200 && res.data.data) {
      let responseData = res.data.data
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          [0]: {
            ...prevFormData[0],
            ji_client_address: responseData.cust_address,
            ji_client_billing_address: responseData.cust_billingaddress,
            ji_client_gst: responseData.cust_gstno
          },
        };
      });
    }
  } catch (error) {
    console.error(error);
  } finally {
    setTimeout(() => {
      setIsOverlayLoader(false)
    }, 10)
  }
};


export const handleScopeOfWorkFunction = async (
  actionSelected,
  editableIndex,
  tableData,
  simpleInwardId,
  formData,
  section,
  setSaveClicked,
  setEditableIndex,
  setPopupIndex,
  popupIndex,
  setPopupOpenAssignment,
  setIsBtnClicked,
  setIsOverlayLoader,
  setTableData,
  setFormData
) => {
  if (actionSelected === "Save" || actionSelected === "customSave") {
    setIsBtnClicked(true);
    let newRowIndex = editableIndex;
    if (actionSelected === "customSave") {
      newRowIndex = tableData.length;
    }
    let payload = {
      ji_scopeofwork: {
        fk_jiid: formData[0]?.ji_id,
        jis_loading_or_soure_id: formData["1"]?.["jis_loading_or_soure_id_" + newRowIndex],
        jis_loading_or_soure: formData["1"]?.["jis_loading_or_soure_id_" + newRowIndex],
        jis_discharge_or_destination_id:
          formData["1"]?.["jis_discharge_or_destination_id_" + newRowIndex],
        jis_discharge_or_destination:
          formData["1"]?.["jis_discharge_or_destination_id_" + newRowIndex],

        fk_port_loading:
          formData["1"]?.["jis_loading_or_soure_id_" + newRowIndex],
        fk_port_unloading:
          formData["1"]?.["jis_discharge_or_destination_id_" + newRowIndex],
        // jis_nameofopsmode:
        //   formData["1"]?.["jis_nameofopsmode_" + newRowIndex],
        // fk_operationtypetid:
        //   formData["1"]?.["fk_operationtypetid_" + newRowIndex],
        fk_scopeworkid:
          formData["1"]?.["fk_scopeworkid_" + newRowIndex],
        jis_scopeofwork:
          formData["1"]?.["fk_scopeworkid_" + newRowIndex],
        fk_activitymasterid:
          formData["1"]?.["fk_activitymasterid_" + newRowIndex],
        jis_unit:
          formData["1"]?.["jis_unit_" + newRowIndex],
        jis_rate:
          formData["1"]?.["jis_rate_" + newRowIndex],
        tenant: GetTenantDetails(1),
        status: "tasked"
      },
    };
    let nonRequiredFields = [];

    for (let obj in payload.ji_scopeofwork) {
      if (
        (payload["ji_scopeofwork"][obj] === undefined ||
          payload["ji_scopeofwork"][obj] === "") &&
        !nonRequiredFields.includes(obj)
      ) {
        const field = section.rows[0].filter((field, index) => {
          if (field.name === obj) {
            field.label = section.headers[index].label;
            return true;
          }
          return false;
        });
        let errLabel = field.length ? field[0].label : obj;
        toast.error(errLabel + " is required", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsBtnClicked(false);
        return;
      }
    }
    console.log('payload', payload)
    let res;

    if (actionSelected !== "customSave") {
      let newMainPayload = {};
      newMainPayload.jis_id =
        tableData[editableIndex].jis_id;
      newMainPayload.ji_scopeofwork = payload.ji_scopeofwork;
      res = await putDataFromApi(getScopeworkUpdateApi, newMainPayload);
    } else {
      res = await postDataFromApi(getScopeworkCreateApi, payload);
    }
    if (res.data.status === 200) {
      getJIsowandactivityData(formData[0]?.ji_id, setTableData, "scope_of_work", formData, setFormData, section)
      setPopupOpenAssignment(false);
      setPopupIndex("");
      setEditableIndex("");
      setIsBtnClicked(false);
      setIsOverlayLoader(false)
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      setIsBtnClicked(false);
      setIsOverlayLoader(false)
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setSaveClicked(false);
    setIsOverlayLoader(false)
  } else if (actionSelected === "Delete") {
    setSaveClicked(true);
    let payload = {
      jis_id: tableData[popupIndex]?.jis_id,
    };
    setIsOverlayLoader(true)
    let res = await deleteDataFromApi(getScopeworkDeleteApi, payload);
    if (res.data.status === 200) {
      getJIsowandactivityData(formData[0]?.ji_id, setTableData, "scope_of_work", formData, setFormData, section)
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsOverlayLoader(false)
    setSaveClicked(false);
  } else if (actionSelected === "Cancel") {
    setEditableIndex("");
  }
};

export const getSingleJiRecord = async (apiEndpoint, ji_id, setFormData, setTabOpen, setIsOverlayLoader, editReordType) => {
  try {
    setIsOverlayLoader(true)
    let res = await postDataFromApi(apiEndpoint, {
      ji_id: ji_id
    });
    if (res?.data?.status === 200 && res.data.data) {
      let responseData = res.data.data;
      responseData.ji_is_ecertification = responseData.ji_is_ecertification ? "E-Certificate" : "Print Hard Copy"
      responseData.ji_client_name = responseData.client_details.client_name
      responseData.ji_client_address = responseData.client_details.client_address
      responseData.ji_client_billing_address = responseData.client_details.client_billing_address
      responseData.ji_client_gst = responseData.client_details.client_gst_no
      responseData.ji_commodity_name = responseData.commodity_details.cmd_name
      responseData.ji_sub_commodity_name = responseData.sub_commodity.sub_cmd_name
      responseData.ji_company_name = responseData.company.company_name
      responseData.ji_place_of_work_name = responseData.place_of_work.pow_name
      responseData.ji_suplier_name = responseData.supplier.supplier_name
      responseData.ji_branch_name = responseData?.branch?.branch_name
      responseData.ji_branch_head_name = responseData?.branch_head?.branch_head_name
      responseData.ji_branch_ex_name = responseData?.ops_executive?.ops_executive_name
      responseData.ji_branch_ex_heade_name = responseData?.ops_executive_head?.ops_executive_head_name
      responseData.ji_branch_sales_person = responseData?.sales_person?.sales_person_name
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          [0]: responseData,
          [1]: responseData
        };
      });
      if (['created'].includes(responseData.ji_internal_status) && editReordType === "analysis") {
        setTabOpen(false)
      }
      else if (['analysis'].includes(responseData.ji_internal_status) && editReordType === "nomination") {
        setTabOpen(false)
      }
      else {
        setTabOpen(true)
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    setTimeout(() => {
      setIsOverlayLoader(false)
    }, 10)
  }
};

export const getGroupParameterDataOperation = async (commodity_id, context, setMasterResponse, operationAssignmentData, editReordType) => {

  try {
    let tempBody = {
      commodity_id: commodity_id,
      context: context,
    };
    let res = await postDataFromApi(OPESGrouoparametersApi, tempBody);
    if (res.data && res.data.status === 200) {
      const actualResponse = res.data.data;
      if (actualResponse.length === 0) {
        toast.error(
          "Parameters not found under this lab for commodity please check with admin",
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
      let parameters = actualResponse.parameters || [];
      let groups = actualResponse.groups || [];
      let isExcludeOptions = false
      let assignmentArr = [];
      if (editReordType !== "analysis") {
        operationAssignmentData.map((singleData) => {
          if (context === "parameter") {
            singleData.jia_set_paramjson.map((paramdata) => {
              if (!assignmentArr.includes(paramdata.param_id)) {
                assignmentArr.push(paramdata.param_id)
              }
            })
          }
          else {
            singleData.jia_set_groupjson.map((paramdata) => {
              if (!assignmentArr.includes(paramdata.group_id)) {
                assignmentArr.push(paramdata.group_id)
              }
            })
          }
        })
        isExcludeOptions = true
      }

      groups = groups.filter((group) => {
        if (isExcludeOptions && !assignmentArr.includes(group.group_id.toString())) {
          return false
        }
        group.name = group.group_name;
        group.id = group.group_id;
        return true;
      });
      parameters = parameters.filter((group) => {
        if (isExcludeOptions && !assignmentArr.includes(group.param_id)) {
          return false
        }
        group.name = group.param_name;
        group.id = group.param_id;
        group.value = group.param_id;
        group.label = group.param_name;
        return true;
      });
      parameters.sort((a, b) => a.param_name.localeCompare(b.param_name));

      const groupsData = {
        model: "smpl_set_groupjson",
        data: groups,
      };
      const parametersdata = {
        model: "smpl_set_paramjson",
        data: parameters,
      };
      setMasterResponse((prev) => [...prev, groupsData, parametersdata]);
    }
  } catch (error) {
    console.error(error);
  } finally {

  }
  return true;
}

export const getBranchDetailsById = async (br_id, setMasterResponse, setIsOverlayLoader) => {
  try {
    setIsOverlayLoader(true)
    let res = await postDataFromApi(getBranchDetailsApi, {
      br_id: br_id
      // br_id: 1
    });
    if (res?.data?.status === 200 && res.data.data) {
      const responseData = res.data.data
      const headeData = responseData.branch_head.map((client) => ({
        id: client.branch_head_id,
        name: client.branch_head_name,
      }));
      const opExeData = responseData.ops_executive.map((client) => ({
        id: client.ops_executive_id,
        name: client.ops_executive_name,
      }));
      const opExeHOData = responseData.ops_executive_head.map((client) => ({
        id: client.ops_executive_head_id,
        name: client.ops_executive_head_name,
      }));
      const sPersonData = [{
        id: 1,
        name: "Sales 1",
      }]
      console.log('headeDataheadeData', headeData)
      const bodyToPass = {
        model: "fk_userbranchheadid",
        data: headeData,
      };
      const bodyToPass2 = {
        model: "fk_useropsexecutiveid",
        data: opExeData,
      };
      const bodyToPass3 = {
        model: "fk_useropsexecutiveheadid",
        data: opExeHOData,
      };
      const bodyToPass4 = {
        model: "fk_usersalespersonid",
        data: sPersonData,
      };
      setMasterResponse((prev) => [...prev, bodyToPass, bodyToPass2, bodyToPass3, bodyToPass4]);
    }
  } catch (error) {
    console.error(error);
  } finally {
    setTimeout(() => {
      setIsOverlayLoader(false)
    }, 10)
  }
}

export const getOpeartionType = async (setMasterResponse) => {
  try {
    let res = await postDataFromApi(MasterListApi, { is_dropdown: true, model_name: "mode" });
    if (res?.data?.status === 200 && res.data.data) {
      const clientData = res.data.data.map((client) => ({
        id: client[0],
        name: client[1],
      }));

      const bodyToPass = {
        model: "fk_operationtypetid",
        data: clientData,
      };
      setMasterResponse((prev) => [...prev, bodyToPass]);
    }
  } catch (error) {
    console.error(error);
  }
};

export const getOPActivityData = async (setMasterResponse) => {
  try {
    let res = await getDataFromApi(getActivityDataApi + "?is_dropdown=" + true);
    if (res?.data?.status === 200 && res.data.data) {
      const clientData = res.data.data.map((client) => ({
        id: client.am_id,
        name: client.am_name,
      }));

      const bodyToPass = {
        model: "fk_activitymasterid",
        data: clientData,
      };
      setMasterResponse((prev) => [...prev, bodyToPass]);
    }
  } catch (error) {
    console.error(error);
  }
};
export const getOPScopeWorkData = async (setMasterResponse) => {
  try {
    let res = await getDataFromApi(getscopeofworkDataApi + "?is_dropdown=" + true);
    if (res?.data?.status === 200 && res.data.data) {
      const clientData = res.data.data.map((client) => ({
        id: client.scope_id,
        name: client.scope_name,
      }));

      const bodyToPass = {
        model: "fk_scopeworkid",
        data: clientData,
      };
      setMasterResponse((prev) => [...prev, bodyToPass]);
    }
  } catch (error) {
    console.error(error);
  }
};
export const getOPLoadingUnLoadingSourceData = async (setMasterResponse) => {
  try {
    let res = await getDataFromApi(getAllPortDataApi + "?is_dropdown=" + true);
    if (res?.data?.status === 200 && res.data.data) {
      const clientData = res.data.data.map((client) => ({
        id: client.port_id,
        name: client.port_name,
      }));

      const bodyToPass = {
        model: "jis_loading_or_soure_id",
        data: clientData,
      };
      const bodyToPass2 = {
        model: "jis_discharge_or_destination_id",
        data: clientData,
      };
      setMasterResponse((prev) => [...prev, bodyToPass, bodyToPass2]);
    }
  } catch (error) {
    console.error(error);
  }
};


export const createQualityAnalysisHandler = async (parameterData, setIsLoading, setIsOverlayLoader, setParameterDataTable, setCustomFormData, setIsSubmit, formData, initialCustomData, setFormData, setTableData, setFinalParamDataSort, setSampleDataTable) => {
  let smpl_set_smpljson = [];
  let smpl_set_paramjson = [];
  let smpl_set_groupjson = [];
  let param_sequance_no = 0
  parameterData.map((paramData, seqIndex) => {
    paramData.smpl_set_smpljson.map((sample) => {
      if (!smpl_set_smpljson.includes(sample)) {
        smpl_set_smpljson.push(sample);
      }
    });
    let basis = [];
    let basiscodes = [];
    if (paramData.smpl_set_basisjson_name.length > 0) {
      basiscodes = paramData.smpl_set_basisjson_name.split(",");
    } else {
      basiscodes = [];
    }
    paramData.smpl_set_basisjson.map((basId, i) => {
      basis.push({
        basis_id: basId,
        basis_code: basiscodes[i],
      });
    });
    if (paramData["is_group_param"] == "Group") {
      let parameters = [];
      const groupParamJson = paramData.groupJsonParameter.filter((singleparam) => {
        singleparam.param_sequence = param_sequance_no
        param_sequance_no++;
        return true
      })
      smpl_set_groupjson.push({
        group_id: paramData.smpl_set_groupjson,
        group_name: paramData.smpl_set_groupjson_name,
        parameters: groupParamJson,
        sequanceNo: seqIndex,
      });
    } else {
      smpl_set_paramjson.push({
        param_id: paramData.smpl_set_paramjson,
        param_name: paramData.smpl_set_paramjson_name,
        std_id: paramData.smpl_set_testmethodjson,
        std_name: paramData.smpl_set_testmethodjson_name,
        basis: basis,
        sequanceNo: seqIndex,
        param_unit: paramData.smpl_set_unit,
        param_sequence: param_sequance_no
      });
      param_sequance_no++;
    }
  });
  const newMainPayload = {
    ji_quality_analysis: {
      fk_jiid: formData[0]?.ji_id,
      jia_set_groupjson: smpl_set_groupjson,
      jia_set_paramjson: smpl_set_paramjson,
      tenant: GetTenantDetails(1)
    },
  };
  // return;
  setIsLoading(true);
  setIsOverlayLoader(true)
  const res = await postDataFromApi(getJIQualityAnalysisCreateApi, newMainPayload);
  if (res.data.status === 200) {

    setParameterDataTable([]);
    setCustomFormData(initialCustomData);
    setIsSubmit(false);
    setIsOverlayLoader(false)
    setTimeout(() => {
      getJIsowandactivityData(formData[0]?.ji_id, setTableData, 'quality_analysis', formData, setFormData, 1, setFinalParamDataSort, setSampleDataTable)

    }, 10)
    toast.success(res.data.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } else {
    toast.error(res.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  setIsOverlayLoader(false)
  setIsLoading(false);
};

export const getJIsowandactivityData = async (ji_id, setTableData, type, formData, setFormData, section, setFinalParamDataSort, setSampleDataTable, isUseForDropdown, setOperationAssignmentData) => {
  try {
    const bodyData = {
      "ji_id": ji_id
    }
    let res = await postDataFromApi(getJIsowandactivityApi, bodyData);
    if (res?.data?.status === 200 && res.data.data) {
      const responseData = res.data.data
      const updatedFormData = { ...formData };
      if (type === "scope_of_work") {
        responseData[type].forEach((singleInwardData, i) => {
          if (!updatedFormData[1]) {
            updatedFormData[1] = {};
          }
          section.rows.forEach((row) => {
            row.forEach((columnName) => {
              const fieldName = `${columnName.name}_${i}`;
              const value =
                columnName.name === "sample_id"
                  ? singleInwardData["smpl_detail_smpl_id"]
                  : singleInwardData[columnName.name];
              if (columnName.name === "smpl_detail_smpl_qty") {
                var spValue = value.split(" ");
                console.log(" spValue", spValue);
                updatedFormData[1][fieldName] = spValue[0];
                updatedFormData[1][
                  `${columnName.name}_unit_${i}`
                ] = spValue.length > 1 ? spValue[1] : "";
              } else {
                if (columnName.name === "smpl_detail_dos") {
                  updatedFormData[1][fieldName] = value;
                }
                else {
                  updatedFormData[1][fieldName] = value;
                }
              }
            });
          });
        });
        setFormData(updatedFormData);
        setTableData(responseData[type])
      }
      else {

        const newArray = responseData[type];
        if (isUseForDropdown) {
          setOperationAssignmentData(newArray)
          return
        }
        let FinalCombinedArray = [];
        newArray.map((singleParamaSet) => {
          let combinedArray = [];
          for (const [key, value] of Object.entries(
            singleParamaSet.jia_set_groupjson
          )) {
            value.param_type = "Group"
            combinedArray.push({ ...value });
          }
          for (const [key, value] of Object.entries(
            singleParamaSet.jia_set_paramjson
          )) {
            value.param_type = "Parameter"
            combinedArray.push({ ...value });
          }
          combinedArray = combinedArray.sort((a, b) => a.sequanceNo - b.sequanceNo);
          FinalCombinedArray.push(combinedArray)
        });
        console.log('newArray', newArray)
        setFinalParamDataSort(FinalCombinedArray)
        if (setSampleDataTable) {
          setSampleDataTable(newArray);
        }
        setTableData(newArray);
      }

    }
  } catch (error) {
    console.error(error);
  }
}

export const qualityAnalysisPageHandleAction = async (
  actionSelected,
  tableData,
  setSaveClicked,
  setEditableIndex,
  popupIndex,
  setIsOverlayLoader,
  setTableData,
  formData,
  setFormData,
  section,
  setFinalParamDataSort,
  setSampleDataTable
) => {
  if (actionSelected == "Delete") {
    setSaveClicked(true);
    setIsOverlayLoader(true)
    let payload = {
      jia_id: tableData[popupIndex]?.jia_id,
    };
    let res = await deleteDataFromApi(getJIQualityAnalysisDeleteApi, payload);
    if (res.data.status === 200) {
      getJIsowandactivityData(formData[0]?.ji_id, setTableData, 'quality_analysis', formData, setFormData, section, setFinalParamDataSort, setSampleDataTable)
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setSaveClicked(false);
    setIsOverlayLoader(false)
  } else if (actionSelected === "Cancel") {
    setEditableIndex("");
  }
};